import React from 'react'
import wifi from '../../images/wifi.png'
import './index.css'

const Error = () => {
  return (
    <div className="error" onClick={reload}>
      <img src={wifi} alt="" />

      <p>加载失败，请点击重试</p>
    </div>
  )
}

const reload = () => {
  window.location.reload(true)
}

export default Error
