import React, { useState, useEffect } from 'react'
import Loading from './Loading'
import Coupon, { IData } from './Coupon'
import Error from './Error'

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isHaveError, setIsHaveError] = useState<boolean>(false)
  const [data, setData] = useState<IData | undefined>(undefined)

  useEffect(() => {
    fetch(
      `/sale/v1/h5/activities/${window.location.search.replace('?id=', '')}`
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          console.log(res)
        }
      })
      .then((resdata) => {
        setIsLoading(false)
        const { code, data, msg } = resdata || {}

        // eslint-disable-next-line
        if (code != undefined) {
          if (code === 0) {
            setData(data)
          } else {
            alert(msg)
          }
        } else {
          setIsHaveError(true)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsHaveError(true)
        setIsLoading(false)
      })
  }, [])

  return isLoading ? (
    <Loading />
  ) : isHaveError ? (
    <Error />
  ) : (
    <Coupon data={data} />
  )
}

export default App
