import React from 'react'
import './index.css';

const Loading = () => (
  <div className="loader">
    <div className="loader-inner line-scale">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export default Loading
