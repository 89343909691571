import React, { useEffect } from 'react'
import Error from '../Error'
import CouponItem, { ICoupon } from '../CouponItem'
import './index.css'

interface IProps {
  data?: IData
}

export interface IData {
  activity_date_end: string
  activity_date_start: string
  coupons: ICoupon[]
  created_at: string
  description: string
  ground_color: string
  header_image_url: string
  mp_qrcode_url: string
  name: string
  qrcode_url: string
  url: string
  status: number
}

const Coupon = (props: IProps) => {
  const { data } = props

  useEffect(() => {
    document.title = `${data?.name || '优惠券领取'}`
  })

  return !!data ? (
    <div
      className="coupon"
      style={{ backgroundColor: data.ground_color || '#F3F3F3' }}
    >
      <img
        className="coupon-banner"
        src={data.header_image_url}
        alt="活动Banner图"
        onClick={() => false}
      />

      {Array.isArray(data.coupons) &&
        data.coupons.map((item: ICoupon, index: number) => (
          <CouponItem key={item.id || index} couponItem={item} />
        ))}

      {getQrcode(data.status, data.mp_qrcode_url)}

      <div className="coupon-rules">
        <div className="text-center">
          <strong>活动规则</strong>
        </div>

        <div className="mt-12 fs-14 text-dark">{data.description}</div>
      </div>
    </div>
  ) : (
    <Error />
  )
}

const getQrcode = (status: number, qrcodeUrl: string): React.ReactNode => {
  switch (status) {
    case 1:
      return <div className="coupon-qrcode">别着急，活动还没有开始哦～</div>

    case 2:
      return (
        <div className="coupon-qrcode">
          <div className="fs-14">
            参与方式，微信环境中长按识别二维码，进入“漫游车小程序”领取！
          </div>

          <div className="coupon-qrcode-imgBox">
            <img src={qrcodeUrl} alt="qrcode" />
          </div>
        </div>
      )

    case 3:
      return <div className="coupon-qrcode">活动已关闭！下次早点来哦～</div>

    case 4:
      return <div className="coupon-qrcode">活动已结束！下次早点来哦～</div>

    default:
      return <div className="coupon-qrcode">来晚了，券已经被领光了</div>
  }
}

export default Coupon
