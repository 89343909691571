import React, { useState, useCallback } from 'react'
import CouponLeft from '../../images/Coupon_left.png'
import CouponRight from '../../images/Coupon_right.png'
import iconMore from '../../images/icon_more.svg'

interface IProps {
  couponItem: ICoupon
}

export interface ICoupon {
  coupon_image_url: string
  discount_cost: number
  discount_enough: number
  id: string
  limit: number
  name: string
  total: number
  type: number
  use_range: Array<string>
  valid_during: string
  valid_date_type: number
  desc: string
}

const CouponItem = (props: IProps) => {
  const { couponItem } = props

  const [isShowMore, setIsShowMore] = useState<boolean>(false)
  const [itemHeight, setItemHeight] = useState<number>(120)

  const moreDetail = useCallback((node) => {
    if (node !== null) {
      setItemHeight(120 + node.getBoundingClientRect().height)
    }
  }, [])

  const triggerMore = () => {
    setIsShowMore(!isShowMore)
  }

  return (
    <div
      className={`coupon-item`}
      style={{ height: `${isShowMore ? itemHeight + 'px' : '120px'}` }}
    >
      <div className="coupon-box">
        <div
          className="coupon-item-left"
          style={{
            backgroundImage: `url("${
              couponItem.coupon_image_url || CouponLeft
            }")`,
          }}
          onClick={() => false}
        >
          <span className="fs-24">&yen;{couponItem.discount_cost}</span>

          <span className="fs-14">
            {couponItem.type === 1
              ? `抵${couponItem.discount_cost}元车费`
              : `满${couponItem.discount_enough}元可用`}
          </span>
        </div>

        <div className="coupon-item-right">
          <div className="coupon-item-right-content">
            <div className="coupon-item-right-content-top">
              <span className="fs-16 coupon-item-right-content-nowrap">
                {couponItem.name}
              </span>

              <span className="fs-12 text-gray coupon-item-right-content-nowrap">
                {couponItem.valid_during}
              </span>

              {Array.isArray(couponItem.use_range) &&
                couponItem.use_range.length > 0 && (
                  <span className="fs-12 text-gray coupon-item-right-content-nowrap">
                    使用范围：{couponItem.use_range.join('、')}
                  </span>
                )}
            </div>

            <div className="fs-12 coupon-item-right-content-bottom">
              <div className="flex_center">
                <span onClick={triggerMore}>查看更多</span>
                <img
                  className={`coupon-icon_more ${
                    isShowMore
                      ? 'coupon-icon_more_show'
                      : 'coupon-icon_more_hide'
                  }`}
                  src={iconMore}
                  alt=""
                />
              </div>
              <span>{getCouponType(couponItem.valid_date_type)}</span>
            </div>
          </div>

          <img src={CouponRight} alt="" onClick={() => false} />
        </div>
      </div>

      <div
        className={`coupon-more ${
          isShowMore ? 'coupon-more_show' : 'coupon-more_hide'
        }`}
        ref={moreDetail}
      >
        <div>
          <div className="coupon-more-title">使用范围</div>
          <div className="fs-12 text-gray">
            {couponItem.use_range.join('、')}
          </div>
        </div>

        <div className="mt-12">
          <div className="coupon-more-title">使用规则&gt;</div>
          <div className="fs-12 text-gray">{couponItem.desc}</div>
        </div>
      </div>
    </div>
  )
}

const getCouponType = (type: number): string => {
  switch (type) {
    case 1:
      return '节假日通用'

    case 2:
      return '仅工作日可用'

    case 3:
      return '仅节假日可用'

    default:
      return '暂不可用'
  }
}

export default CouponItem
